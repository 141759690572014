@import "../../../style/variables.scss";

.intro {
  background-image: url("#{$assets}/fon.jpg");
  background-repeat: no-repeat;
  width: auto;
  background-size: cover;
  height: 800px;
  @include w-800 {
    background-image: url("#{$assets}/homemob.png");
    background-repeat: no-repeat;
    width: auto;
    background-size: cover;
    height: 1100px;
  }
  &__title {
    text-align: center;
    padding-top: 50px;
    margin-top: 0;
  }

  &__background {
    background-color: rgba(0, 49, 77, 0.808);
    width: 100%;
    height: 800px;
    color: aliceblue;
    font-size: 20px;

    @include w-800 {
      padding-top: 30px;
      height: 1060px;
      font-size: 16px;
      display: flex;
      justify-content: center;
    }
  }

  &__block {
    display: flex;
    justify-content: space-between;
    @include w-800 {
      display: block;
      margin: 0 auto;
    }

    &--text {
      display: flex;
      justify-content: center;

      li {
        list-style: disc;
      }
    }
  }
}

.consultation {
  margin-top: 140px;
  @include w-800 {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}
