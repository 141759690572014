@import "./variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wrapper {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  position: relative;
  @include w-1200 {
    max-width: 95%;
    width: 100%;
  }
}

a {
  text-decoration: none;
  text-transform: none;
  color: rgb(67, 66, 75);
}
li {
  list-style: none;
}
.ant-btn {
  padding: 4px 12px;
  border-radius: 2px;
}
.ant-btn:hover {
  padding: 4px 12px;
  border-radius: 2px;
  border: #53b0ff 1px solid;
}
.ant-btn-primary {
  background-color: #53b0ff;
}
.ant-btn-primary:hover {
  background-color: #ffffff;
}
.ant-menu-item-selected {
  background-color: #53b0ff !important;
}
.ant-menu {
  width: 100%;
}
