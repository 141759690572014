.aboutus {
  h1 {
    text-align: center;
  }
  &__items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  &__item {
    width: 396px;
    height: 380px;
    border: 1px solid #cdd1d8;
    border-radius: 4px;
    position: relative;
    margin: 20px auto;
  }

  &__item:hover {
    border: 1px solid rgb(0 91 143 / 86%);
  }

  &__all {
    margin: 20px auto;
    border: 2px solid #cdd1d8;
    text-align: center;
    width: 150px;
    padding: 10px;
    font-weight: 500;
  }
  &__all:hover {
    border: 2px solid rgb(0 91 143 / 86%);
    color: rgb(0 91 143 / 86%);
  }
  &__imgbox {
    margin: 20px;
    display: flex;
    gap: 20px;
    &--top {
      margin-top: 30px;
    }
  }
  &__discripshn {
    margin: 20px;
  }
  &__link {
    font-weight: 500;
    color: rgb(0 91 143 / 86%);
  }
}
