@import "../../style/variables.scss";
.bottom {
  height: 200px;
  ul {
    padding: 0;
  }

  li {
    line-height: 2;
  }
  &__contacts {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    margin: 0;
    @include w-600 {
      display: block;
    }
  }
  div {
    display: flex;
    gap: 20px;
    @include w-600 {
      display: block;
      text-align: center;
    }
  }

  &__confid {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
  }
  &__form {
    text-align: center;
    &--item {
      margin: 10px 0;
      input {
        font-size: 16px;
        border: solid 1px #53b0ff;
        width: 80%;
        padding: 8px;
      }
    }
  }
}
