@import "../../style/variables.scss";

.confid {
  p {
    font-weight: 500;
  }
  @include w-800 {
    padding-top: 60px;
    ul {
      padding: 0 0 0 10px;
    }
  }
  li {
    list-style: decimal;
  }
}
