@import "../../style/variables.scss";

.header {
  width: 100%;
  height: 90px;
  font-family: "Montserrat", sans-serif;
  padding-top: 1px;
  background-color: rgb(0 91 143 / 86%);
  color: #ffffff;
  @include w-800 {
    display: none;
  }
  .b-sevice {
    display: flex;
    &__img {
      margin-top: 20px;
      border-radius: 50%;
    }
  }

  .sevice__level-1 {
    width: 100%;
    padding: 15px;
    height: 120px;
    display: flex;
    justify-content: right;
    gap: 40px;
    span {
      font-weight: 700;
    }
  }

  .sevice__level-2 {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 360px;
    padding: 20px;
    line-height: 2;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .sevice__level-2 a:hover {
    color: #0c75d1;
  }
  .sevice__item-1:hover .sevice__level-2 {
    display: block;
  }
  .sevice__item-2 {
    border-bottom: solid 1px rgba(139, 132, 132, 0.336);
  }
  .border__none {
    border-bottom: none;
  }
  .sevice__item-1 {
    a {
      text-decoration: none;
      text-transform: none;
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .sevice__wa {
    display: flex;

    img {
      position: absolute;
      top: 25px;
    }
  }
  .sevice__item-2 {
    a {
      color: black;
    }
  }
}

.mobheader {
  @include w-800 {
    display: block;
    width: 100%;
    height: 50px;
  }
}
.mob__block {
  display: none;
  @include w-800 {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
