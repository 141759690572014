$assets: "../../../../public/img";

@mixin w-480 {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin w-600 {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin w-800 {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin w-1000 {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin w-1024 {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin w-1200 {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin w-1440 {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin w-1800 {
  @media (max-width: 1800px) {
    @content;
  }
}
