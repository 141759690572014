.home {
  .application {
    width: 100%;
    height: 650px;
    // background-image: url(../../../public/img/fonIntro.jpg);
    // background-repeat: no-repeat;
    // background-size: cover;
    &__content {
      background-color: rgb(0 91 143 / 86%);
      width: 100%;
      height: 650px;
      color: #cdd1d8;
      &--consultation {
        position: relative;
        top: 30px;
      }
    }
  }
}
