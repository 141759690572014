@import "../../../style/variables.scss";
.services {
  margin-top: 50px;
  height: auto;

  &__title {
    padding: 20px 30px;
    color: aliceblue;
    font-weight: 500;
  }
  h1 {
    text-align: center;
  }
  &__items {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    @include w-800 {
      justify-content: center;
    }
  }
  &__item {
    width: 396px;
    height: 220px;
    background-color: rgb(0 91 143 / 86%);
    margin: 20px auto;
    @include w-480 {
      width: 320px;
      height: 200px;
    }
  }
  &__imgbox img {
    vertical-align: middle;
    max-width: 100%;
    margin: 20px 20px 0;
  }
}
