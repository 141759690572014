@import "../../style/variables.scss";

.b-form--head {
  padding: 30px 50px;
  width: 380px;
  min-width: 320px;
  height: 400px;
  right: 0;
  z-index: 4;
  border: 3px solid #fcfcfc;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
}

.b-form--head .form__header .b-title-h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  padding-bottom: 0;
  margin-bottom: 14px;
}

.b-form--head input {
  color: #ffffff;
}
.b-form--head textarea {
  color: #ffffff;
}
.b-form--head input:not([type="checkbox"]),
.b-form--head input:not([type="radio"]),
.b-form--head textarea {
  background-color: #4a8ece73;
  border: 1px solid #4a8fce;
  padding: 10px;
  width: 80%;
  margin: 10px 0;
  font-size: 16px;
}

.b-form--head input::placeholder {
  color: #ffffff;
}
.b-form--head textarea::placeholder {
  color: #ffffff;
}

.b-btnbox,
a.b-btnbox:link,
a.b-btnbox:visited {
  width: 245px;
  min-width: 200px;
  padding: 15px;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 4px;
  background-color: #53b0ff;
}

.form__conf {
  text-decoration: dotted;
  color: #9fd1fd;
  font-weight: 500;
}
@include w-600 {
  h1 {
    font-size: 25px;
  }
}
