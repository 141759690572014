@import "../../../style/variables.scss";
.bankruptcy {
  font-size: 20px;
  line-height: 1.7;

  h1 {
    line-height: 1;
  }
  @include w-800 {
    margin-top: 70px;
  }
}
