.loc {
  overflow: hidden;
}
nav {
  width: 100%;
  height: 50px;
  background-color: rgb(0 91 143 / 86%);
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.b-sevice__img {
  border-radius: 50%;
  margin: 5px 0 0 10px;
}

.burger--btn {
  width: 30px;
  height: 20px;
  margin-left: 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  span {
    position: absolute;
    top: 9px;
    width: 20px;
    background-color: #ffff;
    height: 2px;
  }
}

.burger--btn:before {
  content: "";
  position: absolute;
  top: 0;
  width: 20px;
  background-color: #ffff;
  height: 2px;
}

.burger--btn.activ:before {
  transform: rotate(45deg);
  top: 10px;
}
.burger--span {
  display: none;
}
.burger--btn.activ::after {
  transform: rotate(-45deg);
  top: 10px;
}

.burger--btn:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 20px;
  background-color: #ffff;
  height: 2px;
}

.menu {
  background-color: rgba(0, 91, 143, 0.89);
  color: #ffff;
  padding-top: 50px;
  position: fixed;
  height: 100%;
  width: 100%;
  font-size: 20px;
  z-index: 5;
  overflow: auto;

  li {
    padding: 5px;
    font-weight: 500;
  }

  a {
    color: #ffff;
  }
}
.inactive {
  animation-duration: 3s;
  animation-name: slideout;
  top: -100%;
}

.active {
  animation-duration: 0.3s;
  animation-name: slidein;
  top: 0;
}

@keyframes slidein {
  from {
    top: -100%;
  }

  to {
    top: 0;
  }
}

@keyframes slideout {
  from {
    top: 0;
  }

  to {
    top: -100%;
  }
}
