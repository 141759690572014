@import "../../../style/variables.scss";

.intro {
  height: auto;
  color: rgb(67, 66, 75);
  &__title {
    text-align: center;
  }
  &__content {
    padding: 20px;
    display: flex;
    &--imgblock {
      display: block;
      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 0.1;
      }
    }
    &--img {
      width: 300px;
      height: auto;
      border-radius: 5px;
      line-height: 0.8;
    }
    &--text {
      font-size: 18px;
      padding: 0 50px;
      @include w-800 {
        padding: 20px 5px;
        @include w-480 {
          padding: 5px 1px;
        }
      }
    }
    @include w-800 {
      display: block;
    }
  }
}
