@import "../../style/variables.scss";

.application {
  width: 100%;
  height: 650px;
  background-image: url("../../../public/img/app.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  &__content {
    background-color: rgb(0 91 143 / 86%);
    width: 100%;
    height: 650px;
    color: #cdd1d8;
    h1 {
      text-align: center;
      @include w-800 {
        padding-top: 30px;
        font-size: 25px;
      }
    }

    &--consultation {
      display: flex;
      justify-content: right;
      @include w-800 {
        justify-content: center;
      }
    }
  }
}
