.contacts {
  margin-top: 70px;
  text-align: center;
  ul {
    text-align: left;
    padding: 40px;
  }
  li {
    line-height: 2;
    font-size: 16px;
    font-weight: 500;
  }
}
